import { createReducer, on } from '@ngrx/store';
import { Company } from '../../shared/models/company.model';
import { CompanyInvestorDto, CompanyInvestorStatsDto } from '../../shared/models/dtos/company-investor.dto';
import { User } from '../../shared/models/user.model';
import { DefaultGenericList, GenericList } from '../shared/models/generic-list.model';
import { mergeItemInList } from '../shared/utils/functions';
import {
  clearInvestors,
  loadInvestorCompany,
  loadInvestorCompanyStatsSuccess,
  loadInvestorCompanySuccess,
  loadInvestors,
  loadInvestorsFail,
  loadInvestorsSuccess,
  loadInvestorUser,
  loadInvestorUserSuccess,
  setInvestorItemActiveSuccess,
  setInvestorItemBusinessConsultantSuccess,
  setInvestorsBusinessConsultantFilter,
  setInvestorsQuery,
  updateInvestorCompanyClientSuccess,
} from './investors.actions';

export interface CompanyInvestorList extends GenericList<CompanyInvestorDto> {
  businessConsultantUserId?: number;
}

export interface InvestorsState {
  initialized?: boolean;
  loading?: boolean;
  list?: CompanyInvestorList;
  companiesStats: CompanyInvestorStatsDto[];
  companiesList?: Company[];
  usersList: User[];
}

const initialState: InvestorsState = {
  initialized: false,
  loading: false,
  list: DefaultGenericList<CompanyInvestorDto>(),
  companiesStats: [],
  companiesList: [],
  usersList: [],
};

export const investorsReducer = createReducer(
  initialState,
  on(loadInvestors, (state) => ({
    ...state,
    loading: !state.initialized
  })),
  on(loadInvestorsFail, (state) => ({
    ...state,
    initialized: true,
    loading: false,
    list: {
      ...state.list,
      items: []
    }
  })),
  on(loadInvestorsSuccess, (state, action) => ({
    ...state,
    initialized: true,
    loading: false,
    list: {
      ...state.list,
      items: action.payload.list
    }
  })),
  on(setInvestorsQuery, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      query: action.payload.query,
    }
  })),
  on(setInvestorsBusinessConsultantFilter, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      businessConsultantUserId: action.payload.userId,
    }
  })),
  on(setInvestorItemActiveSuccess, (state, action) => {
    const items = [...state.list.items];
    const index = items.findIndex(i => i.companyId == action.payload.id);
    if (index >= 0) {
      items[index] = {
        ...items[index],
        companyIsActive: action.payload.isActive
      }
    }
    return {
      ...state,
      list: {
        ...state.list,
        items
      }
    };
  }),
  on(setInvestorItemBusinessConsultantSuccess, (state, action) => {
    const { company } = action.payload;
    const item = state.list.items.find(listItem => listItem.companyId === company.id);
    item.businessConsultant = company.businessConsultant;
    return {
      ...state,
      list: {
        ...state.list,
      },
    };
  }),
  on(updateInvestorCompanyClientSuccess, (state, action) => {
    const { company } = action.payload;
    const item = state.list.items.find(listItem => listItem.companyId === company.id);
    item.clientActivationDate = company.clientActivationDate;
    return {
      ...state,
      list: {
        ...state.list,
      },
    };
  }),
  on(loadInvestorCompanyStatsSuccess, (state, action) => ({
    ...state,
    companiesStats: [
      ...state.companiesStats,
      action.payload.stats
    ]
  })),
  on(loadInvestorCompany, (state) => ({
    ...state,
    loading: true
  })),
  on(loadInvestorCompanySuccess, (state, action) => ({
    ...state,
    loading: false,
    companiesList: mergeItemInList(state.companiesList, action.payload.company)
  })),
  on(loadInvestorUser, (state) => ({
    ...state,
    loading: true
  })),
  on(loadInvestorUserSuccess, (state, action) => ({
    ...state,
    loading: false,
    usersList: mergeItemInList(state.usersList, action.payload.user)
  })),
  on(clearInvestors, () => initialState)
);
